html,
body {
  @apply w-full h-full bg-white;

  font-family: 'Helvetica', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold text-slate-700;

  font-family: 'Helvetica', sans-serif;
}

.button {
  @apply bg-blue-500 text-white font-bold py-1 px-2 border-b-4 border-blue-700 rounded cursor-pointer shadow-md;
}

.button:hover {
  @apply bg-blue-400 border-blue-500 shadow-lg;
}

.button:disabled {
  @apply bg-gray-400 border-gray-500 cursor-not-allowed;
}

.button.red {
  @apply bg-red-500 border-red-700;
}

.button.red:hover {
  @apply bg-red-400 border-red-500;
}

.button.green {
  @apply bg-green-500 border-green-700;
}

.button.green:hover {
  @apply bg-green-400 border-green-500;
}

input {
  @apply font-mono;
}
